import React, { useState, useRef, useEffect } from "react";
import styles from "./Chat.module.scss";
import axios from 'axios'
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

function Chat() {
  const ref = useRef(null);
  const [chatText, setChatText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [searchAllBooks, setSearchAllBooks] = useState(false)
  const [answerFromGod, setAnswerFromGod] = useState([{value: 'Empieza a chatear con tu asistente Lexai', type: 'lexai'}])
  const token = window.localStorage.getItem('token')
  const [ws, setWs] = useState({})

  useEffect(() => {
    connect()
  }, [])

  const connect = () => {
    if (token == undefined) {
      return;
    }
    fetch(
      'https://ecehsrh8mi.execute-api.eu-south-2.amazonaws.com/dev/wstoken', {
      headers: {
        Authorization: "Token " + token
      }
    }).then(r => r.json()).then(r => {
      let ws = new WebSocket('wss://v88i01os3i.execute-api.eu-central-1.amazonaws.com/production?token=' + r.token);
      setWs(ws)
    });
  }

  ws.onmessage = event => {
    const out = [...answerFromGod]
    if (out[out.length -1].placeholder === true) {
      out[out.length -1] = {value: '', type: 'lexai', placeholder: false}

    }
    out[out.length -1].value = `${out[out.length -1].value} ${event.data}`

    setAnswerFromGod(out)
    setTimeout(() => {
      ref.current?.scrollBy({top: document.body.scrollHeight, left: 0, behavior: "smooth"});
    }, 100);
  }

  ws.onopen = event => {
    console.log("Connected")
  };
  
  const send = (e) => {
    if (e.key === 'Enter' && chatText !== '') {
      const userMsg = [...answerFromGod]
      userMsg.push({value: chatText, type: 'user'})
      userMsg.push({value: 'Loading...', type: 'lexai', placeholder: true})

      setAnswerFromGod(userMsg);
      ws.send(chatText);
      setChatText('');
      setTimeout(() => {
        ref.current?.scrollBy({top: document.body.scrollHeight, left: 0, behavior: "smooth"});
      }, 100);
    } 
    return
  } 

  const onInput = (e) => {
    setChatText(e.target.value)
  }
  return (
    <div className={styles.main}>
      <div className={styles.wrap}>
        <div className={styles.chatMain} ref={ref}>
          {answerFromGod.map((e, idx) => {
            if (e.type === 'lexai') {
              return <div className={styles.leftBubble} key={idx}>{e.value}</div>
            } 
            return <div className={styles.rigthBubble} key={idx}>{e.value}</div>
          })} 
          { isLoading 
            ? <div>Loading...</div>
            : null
          }
        </div>
        <div className={styles.inputWrap}>
          <input 
            type="text" 
            id="text" 
            value={chatText}
            onInput={(e) => onInput(e)} 
            onKeyDown={(e) => send(e)}
            placeholder="Ask Lexai"
          />
          {/*
          implementation with websockets still missing
          <FormGroup>
            <FormControlLabel 
              control={<Checkbox />} 
              label={<span style={{ fontSize: '12px' }}>Search All Lexai docs</span>} 
              onChange={() => setSearchAllBooks(!searchAllBooks)} 
            />
          </FormGroup>
          */}
        </div>
      </div>
    </div>
  );
}

export default Chat;



  // const sendMsg = (e) => {
  //   if (e.key === 'Enter' && chatText !== '') {
  //     const msgText = [...answerFromGod];
  //     msgText.push({value: chatText, type: 'user'});
  //     setAnswerFromGod(msgText);
  //     setIsLoading(true);

  //     axios.post('https://ecehsrh8mi.execute-api.eu-south-2.amazonaws.com/dev/prompt', {prompt: chatText, global_index: searchAllBooks}, { headers: { Authorization: "Token " + token }}
  //     ).then(r => {
  //       const out = [...msgText]
  //       out.push({value: r.data.response, type: 'lexai'})
        
  //       setAnswerFromGod(out)  
  //       setIsLoading(false);
  //     }).catch(error => {
  //       console.log(error)
  //     })
  //     setChatText('');
  //     setTimeout(() => {
  //       ref.current?.scrollBy({top: document.body.scrollHeight, left: 0, behavior: "smooth"});
  //     }, 50);
  //   }
  // }