import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Logo from '../../images/logo.svg'
import styles from './Header.module.scss'

function Header() {
  const navigate = useNavigate();
  const [user, setUser] = useState({})
  const token = window.localStorage.getItem('token')

  useEffect(() => {
    axios.get('https://ecehsrh8mi.execute-api.eu-south-2.amazonaws.com/dev/get_session', { headers: { Authorization: "Token " + token }})
    .then((r) => {
      setUser(r.data.data)
    })
  }, [token])

  const onLogin = () => {
    window.location.replace('https://ecehsrh8mi.execute-api.eu-south-2.amazonaws.com/dev/login');
  }

  const onLinkClick = (e) => {
    navigate(e)
  }

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <img src={Logo} alt="Korumei Logo" className={styles.logoMobile} />
          { token ?
              <React.Fragment>
                <Button onClick={() => onLinkClick('/upload')} sx={{ my: 2, color: 'white', display: 'block' }} >Upload</Button>
                <Button onClick={() => onLinkClick('/chat')} sx={{ my: 2, color: 'white', display: 'block' }} >Chat</Button>
             </React.Fragment>
             : null
             }
          </Box> 

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <img src={Logo} alt="Korumei Logo" className={styles.logoMobile} />

            <Button onClick={() => onLinkClick('/')} sx={{ my: 2, color: 'white', display: 'block' }}>Home</Button>

            { token ?
              <React.Fragment>
                <Button onClick={() => onLinkClick('/upload')} sx={{ my: 2, color: 'white', display: 'block' }}>Upload</Button>
                <Button onClick={() => onLinkClick('/chat')} sx={{ my: 2, color: 'white', display: 'block' }}>Chat</Button>
             </React.Fragment>
             : null
             }
          </Box>

          { token ?
            <React.Fragment>
              <Box sx={{ mr:2}}>{user?.name}</Box>
              <Box sx={{ flexGrow: 0 }}>
                <IconButton sx={{ p: 0 }}>
                  <Avatar alt="Avatar" src={user?.picture} />
                </IconButton>
              </Box>
            </React.Fragment>
          :  <Button onClick={() => onLogin()} sx={{ my: 2, color: 'white', display: 'block' }}>Login</Button>
          }
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;