import React, { useEffect, useState } from "react";
import styles from "./Upload.module.scss";
import axios from 'axios'

function Upload() {
  const token = window.localStorage.getItem('token');
  const [allDocs, setAllDocs] = useState([]);
  const [documentName, setDocumentName] = useState('');
  const [loading, setLoading] = useState(false);
  const [docsLoading, setDocsLoading] = useState(true);

  useEffect(() => {
    getAllDocs()
  }, [])

  useEffect(() => {
    checkDocs()
  }, [allDocs])
  
  const uploadFile = async () => {
    setLoading(true)
    await axios.post('https://ecehsrh8mi.execute-api.eu-south-2.amazonaws.com/dev/upload', 
      { name: documentName },
      {headers: { Authorization: "Token " + token }})
    .then(r => {
      let file = document.getElementById('file');
      let body = new FormData();
      for (let key in r.data.data.fields) {
        body.append(key, r.data.data.fields[key]);
      }
      body.append('file', file.files[0]);        
      return fetch(r.data.data.url, { method: "POST", body });
    });
    getAllDocs()
    setDocumentName('')
    setLoading(false)
  }

  const getAllDocs = () => {
    setDocsLoading(true)
    axios.get('https://ecehsrh8mi.execute-api.eu-south-2.amazonaws.com/dev/documents', { headers: { Authorization: "Token " + token }}
      ).then(r => {
        setAllDocs(r.data)
      }).catch(error => {
        console.log(error)
      })
      setDocsLoading(false)
  }

  const checkDocs = () => {
    const filtered = allDocs.filter(e => e.ready === false);
    if (filtered.length > 0) {
      setInterval(() => {
        getAllDocs()
        console.log('tatata')
      }, 300000);
    }
  }
  
  const onHandleInput = (e) => {
    if (e.target?.files[0].name) {
      setDocumentName(e.target.files[0].name)
    }
  }

  return (
    <div className={styles.main}>
      <div className={styles.wrap}>
        <div className={styles.uploadDiv}>
          <div className={styles.labelWrap}>
            <label className={styles.uploadButton} onInput={(e) => onHandleInput(e)}>
              { loading 
                ? <input disabled type="file" id="file" onInput={(e) => onHandleInput(e)}></input>
                : <input type="file" id="file" onInput={(e) => onHandleInput(e)}></input>
              }
                Select a file
            </label>
            { documentName !== ''
                ? <span className={styles.label}>{documentName}</span>
                : null
              }
          </div>
          <button onClick={() => uploadFile()}>Upload File</button>
        </div>
        <div className={styles.docListWrap}>
          { loading || docsLoading
            ? <div>Loading...</div>
            : null
          }
          {allDocs.map((e, idx) => {
            return <div key={idx} className={styles.docWrap}>
              <div className={styles.doc}>
                { e.ready 
                ? <span className={styles.ready}>{e.name} - <span>Ready</span></span>
                : <span className={styles.processing}>{e.name} - <span>Processing...</span></span>
                }
              </div>
            </div>
          })}
        </div>

      </div>
      
    </div>
  );
}

export default Upload;
