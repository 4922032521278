import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import Header from '../components/header/Header';
import Landingscreen from './LandingScreen/LandingScreen';
import Chat from './chat/Chat'
import Upload from './upload/Upload'
import styles from './Screens.module.scss';

function Screens() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token')

    if (token) {
      window.localStorage.setItem('token', token)
    }
  }, [])
  
  return (
    <div className={styles.main}>

      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Landingscreen />}/>
          <Route path="/chat" element={<Chat />} />
          <Route path="/upload" element={<Upload />} />
        </Routes>
      </Router>
    </div>
  );
}

export default Screens;
